<template>
  <div class="login container h-100">
    <div class="row h-100 align-items-center pt-4 pb-4">
      <div class="col-12 col-md-6 offset-md-3">
        <div class="card shadow sm p-0 m-0">
          <div class="card-body p-0 m-0">
            <div class="d-block w-100 login-header p-0 m-0">
              <h5 class="text-center letter-spacing-2">Login</h5>
            </div>
            <form action="javascript:void(0)" class="row form-login label-top" method="post">
              <div class="form-group form-floating-label mt-2 col-12">
                <input required type="text" v-model="auth.email" name="email" id="email" placeholder=" " class="form-control top">
                <label for="email" class="font-weight-bold top">Email</label>
              </div>
              <div class="form-group form-floating-label mt-4 col-12">
                <input required type="password" v-model="auth.password" name="password" id="password" placeholder=" " class="form-control top">
                <label for="password" class="font-weight-bold top">Password</label>
              </div>
              <div class="form-group col-12 mb-2 mt-2">
                <button type="submit" :disabled="processing" @click="login" class="btn-branding border-branding btn-block min-width-100">
                  {{ processing ? "Please wait" : "Login" }}
                </button>
                <div v-if="validationErrors" class="global-error mt-2">
                  <ValidationErrors :errors="validationErrors"/>
                </div>
              </div>
              <div v-if="validationErrors" class="col-12 text-center">
                <p>Forgot your password? <router-link :to="{name:'forgotpassword'}">Click here!</router-link></p>
              </div>
              <div class="col-12 text-center">
                <p>Don't have an account? <router-link :to="{name:'register'}">Register Now!</router-link></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import axios from 'axios'
import ValidationErrors from './ValidationErrors'

export default {
  name:"login",
  components: {
    'ValidationErrors': ValidationErrors
  },
  data(){
    return {
      auth:{
        email: '',
        password: '',
      },
      processing:false,
      validationMessage: '',
      validationErrors: ''
    }
  },
  mounted() {
    // Toggle background to be invisible (we want video parallax)
    let main = document.getElementById('mainContent');
    if (main) {
      main.classList.add('main-content-transparent');
      if (main.classList.contains('main-content')) {
        main.classList.remove('main-content');
      }
    }
  },
  beforeDestroy() {
    // Toggle back the background to be visible
    let main = document.getElementById('mainContent');
    if (main) {
      main.classList.add('main-content');
      if (main.classList.contains('main-content-transparent')) {
        main.classList.remove('main-content-transparent');
      }
    }
  },
  methods:{
    ...mapActions({
      signIn:'auth/login'
    }),
    async login(){
      this.processing = true

      await axios.get('/sanctum/csrf-cookie')
      await axios.post('api/login',this.auth).then(({data})=>{
        if (this.$route && this.$route.query && this.$route.query.redirect) {
          this.signIn(this.$route.query.redirect);
        } else {
          this.signIn();
        }
      }).catch(({response})=>{
        if (response && response.data && response.data.errors) {
          this.validationErrors = response.data.errors;
          this.validationMessage = response.data.message;
        } else if (!response) {
          // If response === undefined we are facing 401 error (Unauthorized) - axios does not support 401 error
          // so we need to handle it manually in here or use interceptors
          this.validationErrors = {'wrong': ['Either email or password is not recognised']};
        }
      }).finally(()=>{
        this.processing = false
      })
    }
  }
}
</script>

<style lang="scss">

.login .form-login {
  padding: 10px 20px 10px 20px;
}

.login .login-header {
  width: 100%;
  border-radius: 20px 20px 0 0;
  background: linear-gradient(to left, rgba(128, 0, 128, 0.7), rgba(25, 181, 254, 0.7));
  h5 {
    color: white;
    border-radius: 20px 20px 0 0;
    padding: .5rem;
    text-transform: uppercase;
    text-shadow: 2px 1px #3c3c3c;
  }
}

.login .card {
  border: 0 !important;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.7) !important;
  backdrop-filter: blur(4px) !important;
  input {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
  textarea {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
}

</style>